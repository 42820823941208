<template>

  <div>
    <div class="contents">
      <div class="uk-background-center-center parallax-bg" >
        <h2 class="uk-text-center page-title uk-padding-small">
          Envirionment<br>
          環境事業
        </h2>
        <div class="section-title">
          <span >電解水の特徴</span>
        </div>
        <div class="section-main" >
          <span class="section-detail" >
            水と塩から作る電解水は安心・安全。<br>
            タンパク質や油脂を分解する洗浄効果をもつアルカリ性の電解水と、菌やウィルスに対して効果がある酸性電解水があります。<br>
            電解水は食器や調理器具の洗浄、除菌や清掃、洗濯など衣食住にかかわるあらゆるところで活用されています。
          </span>

          <div class="uk-child-width-1-2@m uk-child-width-1-1@s  uk-margin" uk-grid>
              <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                <div  v-for="(feature, i) in features" :key="i" class="uk-flex" :class="feature.class">
                    <div class="uk-width-1-6 ">{{feature.title}}</div>
                    <div class="uk-width-1-2 ">
                      <p uk-scrollspy="cls: uk-animation-scale-up; delay: 300; repeat: true">
                        {{feature.caption}}
                      </p>
                    </div>
                    <div class="uk-width-1-3 ">{{feature.sub}}</div>
                </div>
              </div>
              <div uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                  <h5>【使用pH値】</h5>
                  <graph-svg style="width:100%"/>
              </div>
          </div>
        </div>
        <div class="section-title">
          <span >活用場面</span>
        </div>
        <div class="section-main" >
          <div class="uk-child-width-1-3@m" uk-grid uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 100; repeat: true">
              <div  v-for="(usageScene, i) in usageScenes" :key="i">
                <div  class="uk-card uk-card-default uk-grid-collapse uk-flex uk-margin" uk-grid>
                    <div class="uk-flex-last@s uk-card-media-right uk-cover-container uk-width-2-3">
                        <img :src="require('@/assets/img/image/page-jigyo-envirionment/' + usageScene.image)" alt="" uk-cover>
                        <canvas></canvas>
                    </div>
                    <div class="uk-width-1-3 " style="padding: 3%">
                        <h5>{{usageScene.title}}</h5>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div class="section-title">
          <span >取扱製品のご紹介</span>
        </div>
        <div class="section-main"  uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .product; delay: 100; repeat: true" >
          <div class="product " v-for="(product, i) in products" :key="i">
            <div  class="uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-3@l uk-text-center" uk-grid>
              <div >
                <h4 class="product-title" >{{product.title}}</h4>
                <div class="uk-child-width-1-4 " uk-grid>
                  <div class="uk-text-center icon-list" v-for="(icon, j) in product.icon" :key="j">
                    <svg-parts :path="'page-jigyo-envirionment/'+iconParts[icon].image" />
                    <span>{{iconParts[icon].title}}</span>
                  </div>
                </div>
              </div>
              <div  class="img-box uk-visible@l"> <img :src="require('@/assets/img/image/page-jigyo-envirionment/' + product.image)" alt="" > </div>
              <div >
                  <table >
                      <tbody>
                          <tr v-for="(list, j) in productDetail[i].list" :key="j" >
                            <th >{{list.title}}</th>
                            <td >{{list.detail}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>
        <div class="section-title">
          <span >プラン</span>
        </div>
        <div class="section-main uk-text-center" >
          <span uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .heading-plan, .uk-card; delay: 100; repeat: true" >
            <h3 class="heading-plan ">購入プランの他にレンタルプランもご用意しております</h3>
            <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match rental" uk-grid>
                <div>
                    <div class="uk-card uk-card-default uk-card-body">
                        <h3 class="uk-card-title">POINT1</h3>
                        <p>レンタル契約が可能です。<br> ※年単位の契約で導入・返却が可能です。</p>
                    </div>
                </div>
                <div>
                    <div class="uk-card uk-card-default uk-card-body">
                        <h3 class="uk-card-title">POINT2</h3>
                        <p>レンタル中に残価のみの支払いで買取りも可能です。</p>
                    </div>
                </div>
                <div>
                    <div class="uk-card uk-card-default uk-card-body">
                        <h3 class="uk-card-title">POINT3</h3>
                        <p>お試し導入が可能です。<br>その他、お気軽にご相談ください。</p>
                    </div>
                </div>
            </div>
          </span>
        </div>
        <mini-manu />
      </div>
    </div>
  </div>
  
</template>


<script>
import JigyoEnvirionmentProduct from '../../mixins/jigyo-envirionment-product.json'
import SvgParts from '../modules/parts/SvgParts.vue'
import GraphSvg from "@/assets/img/image/page-jigyo-envirionment/graph.svg"
import miniManu from'../modules/parts/MiniMenu.vue'

export default {
  components: {
    GraphSvg,
    SvgParts,
    miniManu,
  },
  data: () => ({
    features:[
      {title:'除菌',class:'red-cap',sub:'',caption:'酸性電解水に含まれる次亜塩素酸の力により高い除菌効果を発揮します。' },
      {title:'脱臭',class:'red-cap',sub:'酸性電解水',caption:'酸性電解水により、臭い成分が酸化分解されます。' },
      {title:'漂白',class:'red-cap',sub:'',caption:'酸性電解水に含まれる塩素系成分が黄ばみや黒ずみなどの汚れを分解します。' },
      {title:'洗浄',class:'blue-cap',sub:'アルカリ性電解水',caption:'電気的な力と乳化作用により、細かい部分に入り込んだ汚れも強力に洗浄します。' },

    ],
    usageScenes:[
      {title:'食品工場',image:'scene-food-factory.jpg' },
      {title:'飲食店',image:'scene-restaurant.jpg' },
      {title:'介護施設',image:'scene-nursing-care.jpg' },
      {title:'スポーツジム',image:'scene-gym.jpg' },
      {title:'教育施設',image:'scene-education.jpg' },
      {title:'宿泊施設',image:'scene-hotel.jpg' },
    ],
    products:[
      {title:'＠除菌　330',image:'product-jokin330.png' ,icon:['hand','cleaning','sterilization','space']},
      {title:'＠除菌　550',image:'product-jokin550.png' ,icon:['tableware','cleaning','sterilization','space']},
      {title:'ESS-ZERO　Ⅲ',image:'product-esszero.png' ,icon:['cleaning','machine','sterilization','space']},
      {title:'LES-mini',image:'product-les.png' ,icon:['cleaning','machine','sterilization','space']},
    ],
    iconParts:{
      'hand': {title:'手洗い',image:'icon-hand' },
      'cleaning': {title:'清掃',image:'icon-cleaning' },
      'machine': {title:'機械洗浄',image:'icon-machine' },
      'space': {title:'空間除菌',image:'icon-space' },
      'sterilization': {title:'除菌',image:'icon-sterilization' },
      'tableware': {title:'食品の洗浄',image:'icon-tableware' },
    },
    productDetail:JigyoEnvirionmentProduct
    
  }),

}
</script>



<style scoped lang="scss">

.parallax-bg {
  background-color: #fff;
}

h2{
  color: #fff;
  background-image:url(~@/assets/img/image/sub-links-jigyo-environment.jpg);
  background-position: center;
  background-size: cover;
  padding: 5%;
}

.uk-card{
  height:20vh;
}
.product{
  margin: 2vh;
  padding: 3vh;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);

    .uk-child-width-1-3 > div{
      height:100%
    }
    *table{
      border-collapse:separate;
      border-spacing: 5px;
      width: 100%;
      font-size: 1.2vh;

        th, td{
          border-radius: 5px;
          text-align: left;
          padding: 0.1rem 0.7rem;
        }
        th{
          background-color: #c79852;
          color: white;
          border:solid 1px #927141;
        }
        td{
          white-space: pre-line;
          background-color: #e4d4bc;
          border:solid 1px #af9d85;
        }
    }
    .product-title{
      background-color: rgb(143, 150, 211);
      color: #fff;
      margin: 2vh 5vw;
      padding: 5%;
      text-align: center;
      
    }
    .img-box{
        img{
          max-height:35vh;
        }
    }

}
.uk-grid{
  margin-left: 0!important;
}
.icon-list{
  padding-left: 10px;
}

.red-cap,.blue-cap{
  *{
    margin: 0.5vh 0.5vw;
    align-items: center;
  }
  .uk-width-1-6{
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    padding: 0.5vh 0.5vw;
  }
  .uk-width-1-2{
    font-size: 0.9rem;
  }
  .uk-width-1-3{
    font-size: 1.2rem;
  }
}
.red-cap{
  $red-color: #e6516a;
  .uk-width-1-6{ background-color: $red-color; }
  .uk-width-1-2{ border: 0.2rem solid $red-color; }
  .uk-width-1-3 {color:  $red-color;}
}
.blue-cap{
  $blue-color: #5db3ec;
  .uk-width-1-6{ background-color: $blue-color; }
  .uk-width-1-2{ border: 0.2rem solid $blue-color; }
  .uk-width-1-3 {color:  $blue-color;}
}

.heading-plan {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5vh auto;

    &::before,&::after {
      content: '';
      width: 3px;
      height: 40px;
      background-color: #dbc024;
    }
    &::before {
      margin-right: 30px;
      transform: rotate(-35deg)
    }
    &::after {
      margin-left: 30px;
      transform: rotate(35deg)
    }
}
.rental{
  margin: auto;
  .uk-card > .uk-card-title{
      color: #dbc024;
  }
}

</style>
