<template>
    <div class="svg-wrapper">
        <component :is="svg"/>
    </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
    },
  },
  computed: {
    svg() {
      return () => import(`@/assets/img/image/${this.path}.svg`)
    }
  }
}

</script>



<style scoped lang="scss">

.svg-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
}
.svg-wrapper svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>